import React from 'react';
import {
  Typography,
  Button,
  Credentials,
  ErrorDisplay,
  Loader,
  Input,
  AddImage,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import Reset from './Reset';
import { Tab } from './Tab';
import store from 'store2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Styled from './Profile.style';
interface ProfileProps {
  user: any;
  resetPassword: any;
  resetPasswordRequest: Function;
  updateBusiness: Function;
  getUser: Function;
}
export const Profile: React.FC<ProfileProps> = (props) => {
  const { user, resetPassword, resetPasswordRequest, updateBusiness, getUser } =
    props;

  const doneMessage = _.get(resetPassword, 'status.done');
  const errorMessage = _.get(resetPassword, 'status.error');

  const { error, loading, done } = _.get(user, 'status');

  const storageProfile = store('profileKey');
  const [tabData, setTabData] = React.useState(
    storageProfile === null ? 'profile' : storageProfile
  );
  React.useEffect(() => {
    if (storageProfile === null) {
      store('profileKey', 'profile');
    }
  }, []);

  const tabs = [
    {
      label: 'Profile',
      key: 'profile',
      icon: 'user',
      color: '#0784ab',
      isDisplay: true,
    },
    {
      label: 'Reset password',
      key: 'reset',
      icon: 'lock',
      color: '#0784ab',
      isDisplay: true,
    },
  ];
  const pull_data = (data) => {
    setTabData(data);
    store('profileKey', data);
  };
  const userDataBusiness = _.get(user, 'data.business');
  const description = _.get(user, 'data.business.waterCredentials');
  const logoURL = _.get(user, 'data.business.logoURL');

  const companyUrl = _.get(userDataBusiness, 'url');
  const supplierPageURL = _.get(userDataBusiness, 'supplierPageURL');

  const UploadLogoSchema = Yup.object().shape({
    name: Yup.string(),
    url: Yup.string(),
    waterCredentials: Yup.string(),
    logo: Yup.mixed().test(
      'fileSize',
      'Image is too big! Max image size is 2MB',
      (value) => !value || (value && value.size <= 2000000)
    ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: _.get(userDataBusiness, 'name'),
      waterCredentials: description ? description : '',
      url: companyUrl ? companyUrl : '',
      supplierPageURL: supplierPageURL ? supplierPageURL : '',
      logo: '',
    },
    validationSchema: UploadLogoSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateBusiness(
        values,
        () => {
          setSubmitting(false);
          resetForm();
        },
        () => {
          setSubmitting(false);
        }
      );
      setTimeout(() => {
        getUser();
      }, 500);
      setSubmitting(false);
    },
  });
  const onImageChange = (event) => {
    setImage(event.target.files[0]);
    formik.setFieldValue('logo', event.currentTarget.files[0]);
    setFilename(event.currentTarget.files[0].name);
  };
  const [bannerImage, setImage] = React.useState(null);
  const [filename, setFilename] = React.useState('');

  const profile = () => {
    return (
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Typography size="2" text="Company details" />
            <Typography
              size="p"
              style={{ fontSize: '15px' }}
              text="Update your company details."
            />
          </Col>

          <Col md={6} sm={12} xs={12}>
            <Input
              type="text"
              placeholder="Company name"
              label="Company name"
              id="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Input
              type="text"
              placeholder="Supplier url"
              label="Supplier url"
              id="supplierPageURL"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.supplierPageURL}
            />
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Input
              type="text"
              placeholder="Site url"
              label="Site url"
              id="url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
            />
          </Col>

          <Col md={8} sm={12} xs={12}>
            <AddImage
              onClearClick={() => {
                setImage(null);
                formik.setFieldValue('logo', '');
              }}
              onImageChange={() => onImageChange}
              name="logo"
              label="Add Image"
              disclaimer="Maximun file size 2MB.Recommended aspect ratio is: 5:4. (A minimum resolution of 300x250 pixels)."
              filename={filename ? filename : 'Please select a image'}
              error={
                formik.errors.logo && formik.touched.logo
                  ? formik.errors.logo
                  : undefined
              }
            />
          </Col>
          <Col
            md={4}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {bannerImage || logoURL ? (
              <Styled.ImgWrap
                style={{
                  backgroundImage: bannerImage
                    ? `url(${URL.createObjectURL(bannerImage)})`
                    : `url(${logoURL})`,
                }}
              />
            ) : (
              <div
                style={{
                  background: '#ededed',
                  width: '250px',
                  height: '200px',
                  borderRadius: '7px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Preview
              </div>
            )}
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Styled.DescLabel htmlFor="waterCredentials">
              Description
            </Styled.DescLabel>
            <Styled.Textarea
              placeholder="Add your Company description"
              id="waterCredentials"
              name="waterCredentials"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.waterCredentials}
              maxLength={800}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                color:
                  formik.values.waterCredentials.length > 700 ? 'red' : 'black',
              }}
            >
              {formik.values.waterCredentials.length} / 800 characters
            </div>
          </Col>
          <Col
            sm={12}
            xs={12}
            md={12}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Button type="submit" disabled={formik.isSubmitting}>
              Update
            </Button>
          </Col>
        </Row>
      </form>
    );
  };

  const credentials = () => {
    return <Credentials />;
  };

  const reset = () => {
    return (
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Reset
            doneMessage={doneMessage}
            errorMessage={errorMessage}
            setPreferences={resetPasswordRequest}
          />
        </Col>
      </Row>
    );
  };

  const sizeProps = (data) => {
    switch (data) {
      case 'profile':
        return profile();
      case 'credentials':
        return credentials();
      case 'reset':
        return reset();
      default:
        return reset();
    }
  };
  if (error) {
    return <ErrorDisplay />;
  }

  if (loading && !done) {
    return <Loader />;
  }
  return (
    <Tab data={tabs} getKey={tabData} onClickTab={pull_data}>
      {sizeProps(tabData)}
    </Tab>
  );
};

export default Profile;
